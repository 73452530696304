import { CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid2'

const NotAuthenticated = () => {
    const { t } = useTranslation()
    return (
        <Grid container direction="column" alignItems="center" justifyContent="top" style={{ height: '100vh' }} spacing={2} padding={4}>
            <CircularProgress color="primary" />
            <Typography>{t('main.redirectLogin')}</Typography>
        </Grid>
    )
}

export default NotAuthenticated
