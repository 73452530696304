import { IconButton, InputAdornment, ListSubheader, MenuItem, Skeleton, TableCell, TableRow, TextField, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { IProject, IProjectAndActivity } from '../interface/IProjects'
import { ITimecard, TimecardLine } from '../interface/ISelectedDate'
import { formatISO } from 'date-fns'
import { setSelectedDate } from '../source/slice/SelectedDateSlice'
import { useEffect, useState } from 'react'
import { IOrdersRef } from '../interface/IOrder'
import { IUser } from '../interface/IUser'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3/AdapterDateFnsV3'
import { de } from 'date-fns/locale/de'
import { enUS } from 'date-fns/locale/en-US'
import { EmptyGuid, SEActivityText } from '../config/Values'

const ZDELine = (props: any) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { row, id, hasSpecialOperation } = props as { row: TimecardLine; id: number; hasSpecialOperation: boolean }
    const projects: IProject[] = useSelector((state: any) => state.projects?.value)
    const selectedDate: ITimecard = useSelector((state: any) => state.selectedDate?.value)
    const loadingDate: boolean = useSelector((state: any) => state.loadingDate?.value)
    const orders: IOrdersRef[] = useSelector((state: any) => state.orders?.value)
    const user: IUser = useSelector((state: any) => state.user?.value)
    const { i18n } = useTranslation()
    const [menge, setMenge] = useState<number>(0)
    const [fixAmountDecimals, setFixAmountDecimals] = useState<boolean>(true)

    useEffect(() => {
        if (row.mserp_startdatetime && row.mserp_stopdatetime) {
            const start = new Date(row.mserp_startdatetime).getTime()
            const stop = new Date(row.mserp_stopdatetime).getTime()
            setMenge((stop - start) / 3600000) // convert milliseconds to hours
        } else {
            setMenge(0)
        }
    }, [row.mserp_startdatetime, row.mserp_stopdatetime])

    const getLineAmount = () => {
        if (row.mserp_startdatetime && row.mserp_stopdatetime) {
            const start = new Date(row.mserp_startdatetime).getTime()
            const stop = new Date(row.mserp_stopdatetime).getTime()
            return (stop - start) / 3600000 // convert milliseconds to hours
        }
        return 0
    }

    const handleDateChange = (lineno: any, fieldName: string) => (event: any) => {
        let newCurrentDate: Date
        var currentDate = selectedDate.header?.mserp_timeprofiledate
        var currentDateTime: string
        if (!isNaN(event.getTime()) && currentDate !== undefined && typeof currentDate === 'string') {
            newCurrentDate = new Date(currentDate)
            newCurrentDate.setHours(event.getHours())
            newCurrentDate.setMinutes(event.getMinutes())

            currentDateTime = newCurrentDate.toISOString()
            handleLineChange(lineno, fieldName, currentDateTime)
        }
    }

    const setLineAmountSpecialOperation = (fieldName: string, value: any) => {
        const start = fieldName === 'mserp_startdatetime' ? new Date(value).getTime() : row.mserp_startdatetime ? new Date(row.mserp_startdatetime).getTime() : undefined
        const stop = fieldName === 'mserp_stopdatetime' ? new Date(value).getTime() : row.mserp_stopdatetime ? new Date(row.mserp_stopdatetime).getTime() : undefined
        if (start && stop) {
            const amount = (stop - start) / 1000
            return amount
        }
        return 0
    }

    const handleDropdownChange = (lineno: any, fieldName: string) => (event: any) => {
        const value = event.target.value
        handleLineChange(lineno, fieldName, value)
    }

    const handleLineChange = (lineno: any, fieldName: string, newValue: any) => {
        var newtimecardlines: TimecardLine[] | undefined = selectedDate?.lines
        if (newtimecardlines !== undefined) {
            let newtimecardlinesCopy = [...newtimecardlines]
            switch (fieldName) {
                case 'mserp_projectid':
                    newtimecardlinesCopy[lineno] = {
                        ...newtimecardlinesCopy[lineno],
                        mserp_projectid: newValue,
                        mserp_timeandattendancejobid: '',
                        mserp_indirectactivitycategoryid: '',
                    }
                    break

                case 'mserp_timeandattendancejobid':
                    const description: string | undefined = projects.flatMap((proj: IProject) => proj.activities || []).find((activity: IProjectAndActivity) => activity.mserp_jobid === newValue)?.mserp_description
                    if (description && description.includes(SEActivityText)) {
                        newtimecardlinesCopy[lineno] = {
                            ...newtimecardlinesCopy[lineno],
                            [fieldName]: newValue,
                            mserp_oncalltimeandattendancejobid: 'SE',
                        }
                    } else {
                        newtimecardlinesCopy[lineno] = {
                            ...newtimecardlinesCopy[lineno],
                            [fieldName]: newValue,
                            mserp_oncalltimeandattendancejobid: '',
                        }
                    }
                    break

                case 'mserp_purchid':
                    newtimecardlinesCopy[lineno] = {
                        ...newtimecardlinesCopy[lineno],
                        mserp_purchid: newValue.split('_')[0],
                        mserp_purchlinenumber: Number(newValue.split('_')[1]),
                    }
                    break

                case 'mserp_startdatetime':
                case 'mserp_stopdatetime':
                    newtimecardlinesCopy[lineno] = {
                        ...newtimecardlinesCopy[lineno],
                        [fieldName]: newValue,
                        mserp_calculatedtimeseconds: setLineAmountSpecialOperation(fieldName, newValue),
                    }
                    break

                default:
                    newtimecardlinesCopy[lineno] = {
                        ...newtimecardlinesCopy[lineno],
                        [fieldName]: newValue,
                    }
                    break
            }

            var newselectedDate = JSON.parse(JSON.stringify(selectedDate))
            if (newselectedDate !== undefined && newselectedDate.lines !== undefined) {
                newselectedDate.lines = newtimecardlinesCopy
            }
            dispatch(setSelectedDate(newselectedDate))
        }
    }

    const handleCopyLine = (line: TimecardLine) => {
        const newLine = {
            ...line,
            mserp_mbelectronictimecardlineentityid: EmptyGuid,
        }

        const newSelectedDate = {
            ...selectedDate,
            lines: [...(selectedDate?.lines || []), newLine],
        }

        dispatch(setSelectedDate(newSelectedDate))
    }

    const handleDeleteLine = (lineno: number) => {
        handleLineChange(lineno, 'delete', true)
    }

    const displayRow =
        (row.mserp_indirectactivitycategoryid === '' ||
            row.mserp_timeandattendancejobid === process.env.REACT_APP_JID_ILLNESS ||
            row.mserp_timeandattendancejobid === process.env.REACT_APP_JID_EDUCATION ||
            row.mserp_timeandattendancejobid === process.env.REACT_APP_JID_INTERNAL ||
            row.mserp_timeandattendancejobid === process.env.REACT_APP_JID_WORKSCOUNC ||
            row.mserp_timeandattendancejobid === process.env.REACT_APP_JID_ADDITIONALENTRY) &&
        !row.delete &&
        row.mserp_oncalltimeandattendancejobid !== 'RB'

    useEffect(() => {
        /* if(displayRow && id === 1)
            console.log(id, row.mserp_startdatetime, row.mserp_calculatedtimeseconds) */
        //console.log(displayRow, row.mserp_indirectactivitycategoryid === '', row.mserp_timeandattendancejobid === process.env.REACT_APP_JID_ILLNESS, !row.delete)
    }, [selectedDate, projects, row])

    useEffect(() => {
        //console.log(displayRow, row.mserp_indirectactivitycategoryid === '', row.mserp_timeandattendancejobid === process.env.REACT_APP_JID_ILLNESS, !row.delete)
    }, [])

    var formDisabled = selectedDate?.header?.isTransferred || selectedDate?.header?.calculated || selectedDate?.dayInformation?.mserp_transferred // TODO check whether date is outside of permitted recording period

    return displayRow ? (
        <TableRow key={row.mserp_mbelectronictimecardlineentityid} sx={{ '&:last-child td': { border: 0 } }}>
            <TableCell sx={{ padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>
                {!formDisabled ? (
                    <>
                        <Tooltip title={t('zdelines.delete')}>
                            <IconButton size="small" onClick={() => handleDeleteLine(id)}>
                                <DeleteOutlineIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t('zdelines.copy')}>
                            <IconButton size="small" onClick={() => handleCopyLine(row)}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                ) : (
                    <>
                        <IconButton size="small" onClick={() => handleDeleteLine(id)} disabled={true}>
                            <DeleteOutlineIcon />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleCopyLine(row)} disabled={true}>
                            <ContentCopyIcon />
                        </IconButton>
                    </>
                )}
            </TableCell>
            <TableCell sx={{ padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>
                {!loadingDate ? (
                    row.mserp_timeandattendancejobid !== process.env.REACT_APP_JID_ILLNESS ? (
                        !formDisabled ? (
                            <TextField
                                select
                                value={row.mserp_indirectactivitycategoryid?.toUpperCase() !== 'SYSTEM' ? row.mserp_projectid : row.mserp_timeandattendancejobid}
                                size="small"
                                fullWidth
                                onChange={handleDropdownChange(id, 'mserp_projectid')}
                                error={selectedDate.lines ? selectedDate.lines[id]['mserp_projectid'] === '' && selectedDate.lines[id]['mserp_timeandattendancejobid'] === '' : true}
                            >
                                {projects?.filter((item) => item.projectCategory?.toUpperCase() === 'INTERNAL').length > 0
                                    ? [
                                          <ListSubheader key="id" style={{ lineHeight: '20px', background: 'transparent' }}>
                                              {t('zdelines.internal')}
                                          </ListSubheader>,
                                          ...projects
                                              ?.filter((item) => item.projectCategory?.toUpperCase() === 'INTERNAL')
                                              .map((project: IProject, i: number) => (
                                                  <MenuItem key={project.mserp_projectid + `_` + i} value={project.mserp_projectid}>
                                                      {project.mserp_projectid} - {project.mserp_projectname}
                                                  </MenuItem>
                                              )),
                                      ]
                                    : []}
                                {projects?.filter((item) => item.projectCategory === 'PROJECT').length > 0
                                    ? [
                                          <ListSubheader key="id" style={{ lineHeight: '20px', background: 'transparent' }}>
                                              {t('zdelines.projects')}
                                          </ListSubheader>,
                                          ...projects
                                              ?.filter((item) => item.projectCategory?.toUpperCase() === 'PROJECT')
                                              .map((project: IProject, i: number) => (
                                                  <MenuItem key={project.mserp_projectid + `_` + i} value={project.mserp_projectid}>
                                                      {project.mserp_projectid} - {project.mserp_projectname}
                                                  </MenuItem>
                                              )),
                                      ]
                                    : []}
                            </TextField>
                        ) : (
                            <TextField
                                value={(() => {
                                    const projectId = row.mserp_indirectactivitycategoryid?.toUpperCase() !== 'SYSTEM' ? row.mserp_projectid : row.mserp_timeandattendancejobid
                                    const project = projects.find(item => item.mserp_projectid === projectId)
                                    return project ? `${project.mserp_projectid} - ${project.mserp_projectname}` : `${projectId}`
                                })()}
                                size="small"
                                fullWidth
                                disabled={true}
                            />
                        )
                    ) : (
                        <TextField value={`${row.mserp_timeandattendancejobid} - ${t('zdelines.illnessperday')}`} size="small" fullWidth disabled={true} />
                    )
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>
            <TableCell sx={{ padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>
                {!loadingDate ? (
                    row.mserp_timeandattendancejobid !== process.env.REACT_APP_JID_ILLNESS ? (
                        !formDisabled ? (
                            <TextField
                                select
                                value={row.mserp_timeandattendancejobid}
                                size="small"
                                fullWidth
                                onChange={handleDropdownChange(id, 'mserp_timeandattendancejobid')}
                                error={selectedDate.lines ? selectedDate.lines[id]['mserp_timeandattendancejobid'] === '' : true}
                            >
                                {selectedDate?.lines ? (
                                    projects
                                        ?.filter((proj: IProject) => proj.mserp_projectid === (row.mserp_indirectactivitycategoryid?.toUpperCase() !== 'SYSTEM' ? row.mserp_projectid : row.mserp_timeandattendancejobid))
                                        .map((project: IProject) =>
                                            project.activities?.map((activity: IProjectAndActivity, i: number) => (
                                                <MenuItem key={activity?.mserp_projectid + `_` + i} value={activity?.mserp_jobid} selected>
                                                    {activity?.mserp_description}
                                                </MenuItem>
                                            ))
                                        )
                                ) : (
                                    <></>
                                )}
                            </TextField>
                        ) : (
                            <TextField
                                value={(() => {
                                    const projectId = row.mserp_indirectactivitycategoryid?.toUpperCase() !== 'SYSTEM' ? row.mserp_projectid : row.mserp_timeandattendancejobid
                                    const project = projects.find(item => item.mserp_projectid === projectId)
                                    const activity = project?.activities?.find(item => item.mserp_jobid === row.mserp_timeandattendancejobid)
                                    return activity ? `${activity.mserp_description}` : `${projectId}`
                                })()}
                                size="small"
                                fullWidth
                                disabled={true}
                            />
                        )
                    ) : (
                        <TextField value={`${row.mserp_timeandattendancejobid} - ${t('zdelines.illnessperday')}`} size="small" fullWidth disabled={true} />
                    )
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>
            {user?.worker?.mserp_employmentcategory === 'ANÜ' ? (
                <TableCell sx={{ padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>
                    {!loadingDate ? (
                        <TextField
                            select
                            value={row.mserp_purchid ? row.mserp_purchid + `_` + row.mserp_purchlinenumber : ''}
                            size="small"
                            fullWidth
                            onChange={handleDropdownChange(id, 'mserp_purchid')}
                            disabled={formDisabled}
                            error={selectedDate.lines ? selectedDate.lines[id]['mserp_purchid'] === '' : true}
                        >
                            {orders?.map((order: IOrdersRef, i: number) => (
                                <MenuItem key={order.mserp_purchid + `_` + order.mserp_linenumber} value={order.mserp_purchid + `_` + order.mserp_linenumber} selected>
                                    {order.mserp_purchid} - {order.mserp_linenumber}
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        <Skeleton variant="text" />
                    )}
                </TableCell>
            ) : (
                <></>
            )}

            <TableCell
                sx={{
                    ...(hasSpecialOperation && { padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }),
                    ...(!hasSpecialOperation && { padding: { xs: '16px 16px 16px 8px', md: '16px', lg: '16px 16px 16px 8px', xl: '16px' } }),
                }}
            >
                {!loadingDate ? (
                    <>
                        <TextField
                            //defaultValue={(row.mserp_calculatedtimeseconds ? row.mserp_calculatedtimeseconds / 60 / 60 : 0).toFixed(2)}
                            value={
                                fixAmountDecimals
                                    ? (Math.floor(Math.round(row.mserp_calculatedtimeseconds ? row.mserp_calculatedtimeseconds * 100 / 36 : 0) / 100) / 100).toFixed(2)
                                    : Math.floor(Math.round(row.mserp_calculatedtimeseconds ? row.mserp_calculatedtimeseconds * 100 / 36 : 0) / 100) / 100
                            }
                            type="number"
                            size="small"
                            disabled={formDisabled || row.mserp_oncalltimeandattendancejobid === 'SE'}
                            sx={{ width: '120px' }}
                            slotProps={{
                                htmlInput: { style: { textAlign: 'center' }, min: 0, step: 0.25 },
                                input: {
                                    endAdornment: <InputAdornment position="start">h</InputAdornment>,
                                },
                            }}
                            onBlur={(e: any) => setFixAmountDecimals(true)}
                            onChange={(e: any) => {
                                setFixAmountDecimals(false)
                                handleLineChange(id, 'mserp_calculatedtimeseconds', Math.floor(Number((e.target.value * 100).toFixed(1))) * 36)
                            }}
                            error={selectedDate.lines && selectedDate.lines[id]['mserp_calculatedtimeseconds'] ? selectedDate.lines[id]['mserp_calculatedtimeseconds'] < 0 : true}
                        />
                    </>
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>
            {row.mserp_oncalltimeandattendancejobid !== undefined && row.mserp_oncalltimeandattendancejobid === 'SE' ? (
                <>
                    <TableCell sx={{ padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}>
                        {!loadingDate ? (
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? de : enUS}>
                                <TimePicker
                                    views={['hours', 'minutes']}
                                    ampm={false}
                                    slotProps={{ textField: { size: 'small' } }}
                                    sx={{ width: '120px' }}
                                    defaultValue={row.mserp_startdatetime ? new Date(row.mserp_startdatetime) : null}
                                    onChange={handleDateChange(id, 'mserp_startdatetime')}
                                    disabled={formDisabled}
                                />
                            </LocalizationProvider>
                        ) : (
                            <Skeleton variant="text" />
                        )}
                    </TableCell>
                    <TableCell sx={{ padding: { xs: '16px 16px 16px 8px', md: '16px', lg: '16px 16px 16px 8px', xl: '16px' } }}>
                        {!loadingDate ? (
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={i18n.language === 'de' ? de : enUS}>
                                <TimePicker
                                    views={['hours', 'minutes']}
                                    ampm={false}
                                    slotProps={{ textField: { size: 'small' } }}
                                    sx={{ width: '120px' }}
                                    defaultValue={row.mserp_stopdatetime ? new Date(row.mserp_stopdatetime) : null}
                                    onChange={handleDateChange(id, 'mserp_stopdatetime')}
                                    disabled={formDisabled}
                                />
                            </LocalizationProvider>
                        ) : (
                            <Skeleton variant="text" />
                        )}
                    </TableCell>
                </>
            ) : hasSpecialOperation ? (
                <>
                    <TableCell sx={{ padding: { xs: '16px 8px', md: '16px', lg: '16px 8px', xl: '16px' } }}></TableCell>
                    <TableCell sx={{ padding: { xs: '16px 16px 16px 8px', md: '16px', lg: '16px 16px 16px 8px', xl: '16px' } }}></TableCell>
                </>
            ) : (
                <></>
            )}
            {/*<TableCell>
                {!loadingDate ? (
                    <>
                        <TextField value={getLineAmount().toFixed(2)} type="number" size="small" disabled sx={{ width: '120px' }} />
                    </>
                ) : (
                    <Skeleton variant="text" />
                )}
            </TableCell>*/}
        </TableRow>
    ) : (
        <></>
    )
}

export default ZDELine
