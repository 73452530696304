import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import '@mercedes-benz/typeface-mb-corpo-s'
import '@mercedes-benz/typeface-mb-corpo-a'
import { Provider } from 'react-redux'
import { store } from './source/Store'
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './authConfig'
import { MsalProvider } from '@azure/msal-react'
import './config/i18n'

const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts()
    if (accounts && accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0])
    }
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as AuthenticationResult).account) {
        const account = (event.payload as AuthenticationResult).account
        msalInstance.setActiveAccount(account)
    }
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <MsalProvider instance={msalInstance}>
        <Provider store={store}>
            <App />
        </Provider>
    </MsalProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
